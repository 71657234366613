import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/vsts/work/1/s/src/layouts/mdlayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Accessibility Statement for Dynos`}</h1>
    <p>{`Dynos INC
is committed to ensuring digital accessibility for people with disabilities.
We are continually improving the user experience for everyone and applying the relevant accessibility standards.`}</p>
    <h3>{`Measures to support accessibility`}</h3>
    <p>{`Dynos INC
takes the following measures to ensure accessibility of
Dynos.io:`}</p>
    <ul>
      <li parentName="ul">{`Include accessibility throughout our internal policies.`}</li>
      <li parentName="ul">{`Assign clear accessibility targets and responsibilities.`}</li>
    </ul>
    <h3>{`Conformance status`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/standards-guidelines/wcag/"
      }}>{`Web Content Accessibility Guidelines (WCAG)`}</a>{` defines requirements for designers and developers to improve accessibility for people with disabilities. It defines three levels of conformance: Level A, Level AA, and Level AAA. Dynos.io is partially conformant with WCAG 2.0 level AA. Partially conformant means that some parts of the content do not fully conform to the accessibility standard.`}</p>
    <h3>{`Additional accessibility considerations`}</h3>
    <p>{`We have been working on meeting the Level AA standards for all sections of our application. Our goal is to make all the editor controls accessible via keyboard without having to use mouse.`}</p>
    <h3>{`Feedback`}</h3>
    <p>{`We welcome your feedback on the accessibility of
Dynos.io.
Please let us know if you encounter accessibility barriers on
Dynos.io:`}</p>
    <ul>
      <li parentName="ul">{`E-mail:
`}<a parentName="li" {...{
          "href": "mailto:contact@dynos.io"
        }}>{`contact@dynos.io`}</a></li>
      <li parentName="ul">{`Twitter: @dynosio`}</li>
    </ul>
    <p>{`We try to respond to feedback within 2 business days.`}</p>
    <h3>{`Compatibility with browsers and assistive technology`}</h3>
    <p>{`Dynos.io
is designed to be compatible with the following assistive technologies:`}</p>
    <ul className="technical-information compatible-environments"></ul>
    <p>{`Dynos.io
is not compatible with:`}</p>
    <ul>
      <li parentName="ul">{`We are testing Dynos with the latest versions of Chrome, Safari, and Firefox only to ensure to meet accessibility requirements. Dynos is not tested on any of the Edge or Internet Explorer browsers.`}</li>
    </ul>
    <h3>{`Technical specifications`}</h3>
    <p>{`Accessibility of
Dynos.io
relies on the following technologies to work with the particular combination of web browser and any assistive technologies or plugins installed on your computer:`}</p>
    <ul>
      <li parentName="ul">{`HTML`}</li>
      <li parentName="ul">{`CSS`}</li>
      <li parentName="ul">{`JavaScript`}</li>
    </ul>
    <p>{`These technologies are relied upon for conformance with the accessibility standards used.`}</p>
    <h3>{`Assessment approach`}</h3>
    <p>{`Dynos INC
assessed the accessibility of
Dynos.io
by the following approaches:`}</p>
    <ul>
      <li parentName="ul">{`Self-evaluation`}</li>
    </ul>
    <hr></hr>
    <p>{`This statement was created on 2 August 2020
using the `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/planning/statements/"
      }}>{`W3C Accessibility Statement Generator Tool`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      